import {
  Avatar,
  Flex,
  Icon,
  Link,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';

import {SearchBar} from 'components/navbar/searchBar/SearchBar';
import {SidebarResponsive} from 'components/sidebar/Sidebar';
import PropTypes from 'prop-types';
import React from 'react';

import {FaEthereum} from 'react-icons/fa';
import routes from 'routes';
import {useAuth} from 'hooks/useAuth';
import {
  NavLink,
  useNavigate,
} from "react-router-dom";


export default function HeaderLinks(props: { secondary: boolean }) {
  const {secondary} = props;

  const {
    logout,
    user,
  } = useAuth();
  const navigate = useNavigate();

  const navbarIcon = useColorModeValue('gray.400', 'white');
  let menuBg = useColorModeValue('white', 'navy.800');
  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const ethColor = useColorModeValue('gray.700', 'white');
  const borderColor = useColorModeValue('#E6ECFA', 'rgba(135, 140, 189, 0.3)');
  const ethBg = useColorModeValue('secondaryGray.300', 'navy.900');
  const ethBox = useColorModeValue('white', 'navy.800');
  const shadow = useColorModeValue(
    '14px 17px 40px 4px rgba(112, 144, 176, 0.18)',
    '14px 17px 40px 4px rgba(112, 144, 176, 0.06)'
  );

  return <Flex
    w={{sm: '100%', md: 'auto'}}
    alignItems='center'
    flexDirection='row'
    bg={menuBg}
    flexWrap={secondary ? {base: 'wrap', md: 'nowrap'} : 'unset'}
    p='10px'
    borderRadius='30px'
    boxShadow={shadow}>
    {/* <SearchBar
      mb={() => {
        if (secondary) {
          return {base: '10px', md: 'unset'};
        }
        return 'unset';
      }}
      me='10px'
      borderRadius='30px'
    /> */}
    <Flex
      bg={ethBg}
      display={secondary ? 'flex' : 'none'}
      borderRadius='30px'
      ms='auto'
      p='6px'
      align='center'
      me='6px'>
      <Flex align='center' justify='center' bg={ethBox} h='29px' w='29px' borderRadius='30px' me='7px'>
        <Icon color={ethColor} w='9px' h='14px' as={FaEthereum}/>
      </Flex>
      <Text w='max-content' color={ethColor} fontSize='sm' fontWeight='700' me='6px'>
        1,924
        <Text as='span' display={{base: 'none', md: 'unset'}}>
          {' '}
          ETH
        </Text>
      </Text>
    </Flex>
    <SidebarResponsive routes={routes}/>
    {
      !!user
      && <Menu>
        <MenuButton p='0px'>
          <Avatar
            _hover={{cursor: 'pointer'}}
            color='white'
            name={user.name}
            bg='#11047A'
            size='sm'
            w='40px'
            h='40px'
          />
        </MenuButton>
        <MenuList boxShadow={shadow} p='0px' mt='10px' borderRadius='20px' bg={menuBg} border='none'>
          <Flex w='100%' mb='0px'>
            <Text
              ps='20px'
              pt='16px'
              pb='10px'
              w='100%'
              borderBottom='1px solid'
              borderColor={borderColor}
              fontSize='sm'
              fontWeight='700'
              color={textColor}>
              👋&nbsp; Hello there!
            </Text>
          </Flex>
            <Flex flexDirection='column' p='10px'>
              <MenuItem>
                <Link
                  as={NavLink}
                  to='/intel/profile-settings'
                  mt={{
                    base: '0px',
                    md: '10px',
                    lg: '0px',
                    xl: '10px',
                    '2xl': '0px'
                  }}
                >
                  <Text
                    fontWeight='semibold'
                    bg='gray.100'
                    _hover={{bg: 'gray.200'}}
                    borderRadius='8px'
                    p='14px'
                    fontSize='sm'
                  >
                    Profile settings
                  </Text>
                </Link>
              </MenuItem>
                <MenuItem>
                  <Text
                    fontWeight='semibold'
                    borderRadius='8px'
                    p='14px'
                    bg='gray.100'
                    _hover={{bg: 'gray.200'}}
                    onClick={
                      (e) => {
                        e?.preventDefault();
                        e?.stopPropagation();

                        logout();
                        navigate('/login');
                      }
                    }
                    fontSize='sm'
                  >
                    Log out
                  </Text>
                </MenuItem>
            </Flex>
        </MenuList>
      </Menu>
    }
  </Flex>;
}

HeaderLinks.propTypes = {
  variant: PropTypes.string,
  fixed: PropTypes.bool,
  secondary: PropTypes.bool,
  onOpen: PropTypes.func
};
