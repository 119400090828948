import useAxios from "./use-axios";


export const useCheckRegToken = (regToken: string) => useAxios(`/registration/${regToken}`);

export const useRegister = () => useAxios(
  {
    url: "/registration",
    method: "POST",
  },
  { manual: true }
);

export const useGetEditor = () => useAxios({url: "/profile"});

export const useUpdateBillingInfo = () => useAxios(
  {
    url: "/billing",
    method: "PUT"
  },
  {manual: true},
);

export const useUpdateProfileSettings = () => useAxios(
  {
    url: "/settings",
    method: "PUT"
  },
  {manual: true},
);

export const useGetMonthlyReport = () => useAxios({url: "/report"});

export const useGetCerts = () => useAxios(
  {
    url: "/completion-certs",
    method: "POST",
  });

export const useForgotPassword = () => useAxios(
  {
    url: "/forgot-password-request",
    method: "POST",
  },
  { manual: true }
);

export const useChangePassword = () => useAxios(
  {
    url: "/change-password",
    method: "PUT"
  },
  {manual: true},
);

export const useResetPassword = () => useAxios(
  {
    url: "/reset-password",
    method: "PUT"
  },
  {manual: true},
);

export const useUpdateTermsAndConditions = () => useAxios(
  {
    url: `/terms-and-conditions`,
    method: "PUT"
  },
  {manual: true},
);

