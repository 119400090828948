import React,
  {
    useCallback,
  } from "react";
import {
  Box,
  Center,
  CircularProgress,
  Flex,
  Link,
  Progress,
  Select,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  VStack,
} from '@chakra-ui/react';
import {
  useGetMonthlyReport,
  useGetCerts,
} from 'api/user-api';
import {AiOutlineDownload} from 'react-icons/ai'
import moment from "moment";


export default function BillingPage() {

  const [
    {
      data: monthlyReport,
      loading: getMonthlyReportInProgress,
    }
  ] = useGetMonthlyReport()
  const [
    {
      data: certsData,
      loading: getCertsInProgress,
    },
    getCerts,
  ] = useGetCerts()

  const getCertsOfSelectedYear = useCallback(
    async (year: string) => {
      await getCerts({data: {year: year && parseInt(year) || moment().year()}});
    },
    [getCerts],
  );

  return <>
    <Text as='b'  ml='20px' fontSize='3xl'>Billing</Text>
    <Flex direction='row' justifyContent='space-between' mt='30px'>
      {
        (
          getCertsInProgress
          || getMonthlyReportInProgress
        ) && <VStack align="flex-end" position="absolute" top={0} left={0} right={0}>
          <Progress isIndeterminate size="sm" width="100%" />
          <CircularProgress size="24px" isIndeterminate pr={2} />
        </VStack>
      }
      {
        monthlyReport
        && <Box width="40%"  h='60%'>
          <Flex direction='column' alignItems='center' justifyContent='space-around'>
            <Text ml='30px' fontWeight="bold" fontSize='20px'>Monthly reports:</Text>

            {
              Object.values(monthlyReport).map(
                (report: any, i: number) => <Box
                  key={i}
                  shadow='md'
                  // borderWidth='1px'
                  m='20px'
                  p='1px'
                  fontSize='15px'
                  width='80%'
                  borderRadius='20px'
                  backgroundColor='white'
                >
                  <Table bg='white' borderRadius='20px' height='200px'>
                    <Thead>
                      <Tr borderBottom='solid black'>
                        <Th fontSize='13px'>{`${i === 0 ? 'Current' : 'Previous'} month`}</Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      <Tr borderTop='solid lightgrey'>
                        <Td fontWeight="bold">Ongoing projects:</Td>
                        <Td>{report.openProjectCount}</Td>
                      </Tr>
                      <Tr borderTop='solid lightgrey'>
                        <Td fontWeight="bold">Finished projects:</Td>
                        <Td>{report.finishedProjectCount}</Td>
                      </Tr>
                      <Tr borderTop='solid lightgrey'>
                        <Td fontWeight="bold">Edited photos:</Td>
                        <Td>{report.editedPhotosCount}</Td>
                      </Tr>
                      {
                        report.EUR > 0
                        && (
                          <Tr borderTop='solid lightgrey'>
                            <Td fontWeight="bold">EUR summary:</Td>
                            <Td>{report.EUR} EUR</Td>
                          </Tr>
                        )
                      }
                      {
                        report.HUF > 0
                        && (
                          <Tr borderTop='solid lightgrey'>
                            <Td fontWeight="bold">HUF summary:</Td>
                            <Td>{report.HUF} HUF</Td>
                          </Tr>
                        )
                      }
                    </Tbody>
                  </Table>
                </Box>
              )
            }
          </Flex>
        </Box>
      }
      {
        certsData
        && <Box p='10px' fontSize='15px' width="50%" mr='30px'>
          <Text mb="30px" fontWeight="bold" fontSize="20px" textAlign='center'>
            Certificate Of Completion
          </Text>
          <Box m='10px auto' width="30%">
            <Select
              placeholder='Select year'
              onChange={
                (e) => getCertsOfSelectedYear(e.target.value)
              }
              bg='white'
            >
              {
                Array.from(
                  {length: moment().year() - 2023 + 1},
                  (_, i) => <option key={i} value={moment().year() - i}>
                    {moment().year() - i}
                  </option>
                )
              }
            </Select>
          </Box>
          <Table variant="simple" backgroundColor='white' borderRadius='20px'>
            <Thead>
              <Tr>
                <Th fontSize='15px'>Period</Th>
                <Th fontSize='15px'><Center>EUR </Center></Th>
                <Th fontSize='15px'><Center>HUF </Center></Th>
              </Tr>
            </Thead>
            <Tbody>
              {/* Loop through certs and display each entry */}
              {
                Object.entries(certsData).map(
                  ([period, certsByCurrency]: any, index: number) => <Tr key={index}>
                    <Td>{period}</Td>
                    <Td>
                      <Center>
                        {
                          certsByCurrency['HUF']
                            ? <Link href={certsByCurrency['HUF'].url} isExternal><AiOutlineDownload/></Link>
                            : '-'
                        }
                      </Center>
                    </Td>
                    <Td>
                      <Center>
                        {
                          certsByCurrency['EUR']
                            ? <Link href={certsByCurrency['EUR'].url} isExternal><AiOutlineDownload/></Link>
                            : '-'
                        }
                      </Center>
                    </Td>
                  </Tr>
                )
              }
            </Tbody>
          </Table>
        </Box>
      }
    </Flex>
  </>
}
