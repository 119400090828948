import {
  Button,
  Flex,
  useColorModeValue,
} from '@chakra-ui/react';
import { useDropzone } from 'react-dropzone';
import {useCallback} from "react";


function Dropzone(props: { Content: Function; upload: Function; [x: string]: any }) {
	const { Content, upload, ...rest } = props;

  const onDrop = useCallback(
    (acceptedFiles: any) => {
      upload([]);
      setTimeout(
        () => upload(acceptedFiles),
        250
      );
    },
    []
  );

	const { getRootProps, getInputProps, isDragActive } = useDropzone({onDrop});
	const bg = useColorModeValue('gray.100', 'navy.700');
	const borderColor = useColorModeValue('secondaryGray.100', 'whiteAlpha.100');

  return <Flex
    align='center'
    justify='center'
    bg={bg}
    border='1px dashed'
    borderColor={borderColor}
    borderRadius='16px'
    w='100%'
    h='max-content'
    minH='100%'
    cursor='pointer'
    {...getRootProps({ className: 'dropzone' })}
    {...rest}
  >
    <input {...getInputProps()} />
    <Button variant='no-effects' h='fit-content'>
      {/*// @ts-ignore*/}
      <Content active={!!isDragActive} />
    </Button>
  </Flex>;
}

export default Dropzone;
