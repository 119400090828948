const Card = {
	baseStyle: (props: any) => ({
		p: '20px',
		display: 'flex',
		flexDirection: 'column',
		borderRadius: '20px',
		wordWrap: 'break-word',
		backgroundClip: 'border-box',
		m:'10px',
		bg:'white'
	}),
};

export const CardComponent = {
	components: {
		Card
	}
};
