/* eslint-disable */

import { NavLink, useLocation } from 'react-router-dom';
// chakra imports
import { Box, Flex, HStack, Text, useColorModeValue } from '@chakra-ui/react';

export function SidebarLinks(props: {
	routes: RoutesType[];
}) {
	//   Chakra color mode
	let location = useLocation();
	let activeColor = useColorModeValue('white', 'white');
	let inactiveColor = useColorModeValue('white', 'white');
	let activeIcon = useColorModeValue('white', 'white');
	let textColor = useColorModeValue('white', 'white');
	let brandColor = useColorModeValue('brand.500', 'white');

	const { routes } = props;

	// verifies if routeName is the one active (in browser input)
	const activeRoute = (routeName: string) => {
		return location.pathname.includes(routeName);
	};

	// this function creates the links from the secondary accordions (for example auth -> sign-in -> default)
	const createLinks = (
		routes: RoutesType[],
	) => routes.map(
    (
      route: RoutesType,
      index: number
    ) => <NavLink key={index} to={route.path}>
      {
        route.icon
          ? <Box>
            <HStack
              spacing={activeRoute(route.path.toLowerCase()) ? '22px' : '26px'}
              py='5px'
              ps='10px'>
              <Flex w='100%' alignItems='center' justifyContent='center'>
                <Box
                  color={activeRoute(route.path.toLowerCase()) ? activeIcon : textColor}
                  me='18px'>
                  {route.icon}
                </Box>
                <Text
                  fontSize='20px'
                  me='auto'
                  color={activeRoute(route.path.toLowerCase()) ? 'white'  : textColor}
                  fontWeight={activeRoute(route.path.toLowerCase()) ? 'bold' : 'normal'}>
                  {route.name}
                </Text>
              </Flex>
              <Box
                h='36px'
                w='4px'
                bg={activeRoute(route.path.toLowerCase()) ? 'white' : 'transparent'}
                borderRadius='5px'
              />
            </HStack>
          </Box>
        : <Box>
          <HStack
            spacing={activeRoute(route.path.toLowerCase()) ? '22px' : '26px'}
            py='5px'
            ps='10px'>
            <Text
              me='auto'
              color={activeRoute(route.path.toLowerCase()) ? activeColor : inactiveColor}
              fontWeight={activeRoute(route.path.toLowerCase()) ? 'bold' : 'normal'}>
              {route.name}
            </Text>
            <Box h='36px' w='4px' bg='brand.400' borderRadius='5px' />
          </HStack>
        </Box>
      }
    </NavLink>
  );

  //  BRAND
	return <>{createLinks(routes)}</>
}

export default SidebarLinks;
