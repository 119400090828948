export const linkStyles = {
	components: {
		Link: {
			baseStyle: {
				textDecoration: 'none',
				boxShadow: 'none',
				_focus: {
					boxShadow: 'none'
				},
				_active: {
					boxShadow: 'none'
				},
				_hover: {
					textDecoration: 'none',
					border: 'none'
				}
			},
      variants: {
        photonLavender: {
          color: 'photonLavender.100',
          _hover: {
            color: 'photonNeonPurple.100',
          }
        }
      },
			_hover: {
				textDecoration: 'none',
				border: 'none'
			}
		}
	}
};
