import React, {useCallback, useEffect} from "react";
import {
  Outlet,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { useAuth } from "../hooks/useAuth";
import { useLocalStorage } from "../hooks/useLocalStorage";

export const ProtectedLayout = () => {
  let location = useLocation();
  const navigate = useNavigate();
  const { getUser } = useAuth();
  const [previousPath, setPreviousPath] = useLocalStorage('previousPath', null);

  const refreshCredentials = useCallback(
    () => {
      if (document.visibilityState === "visible") {
        getUser().then(
          (user: any) => {
            if (!user) {
              setPreviousPath(location.pathname);
              navigate("/login", {replace: true});
            } else if (user && (!user.acceptsTermsOfUse || !user.privacyNotice)) {
              navigate("/policy-and-conditions", {replace: true});
            }
          }
        );
      }
    },
    []
  );

  useEffect(
    refreshCredentials,
    [location]
  );

  useEffect(
    () => {
      document.removeEventListener('visibilitychange', refreshCredentials);
      document.addEventListener('visibilitychange', refreshCredentials);
    },
    []
  );

  return <Outlet />;
};
