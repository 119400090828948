
export const formErrorStyles = {
  components: {
    FormError: {
      variants: {
        photonFormError: {
          text: {
            mb: '20px',
            mt: '-4px',
          },
        },
      },
    },
  },
};
