import React from "react";
import {
  NavLink,
  useNavigate,
} from "react-router-dom";
import {useForm} from 'react-hook-form';
import {zodResolver} from '@hookform/resolvers/zod';
import {z} from 'zod';

import {MdOutlineRemoveRedEye} from "react-icons/md";
import {RiEyeCloseLine} from "react-icons/ri";

import {
  Box,
  Button,
  Flex,
  Heading,
  Icon,
  InputRightElement,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";

import DefaultAuth from "layouts/auth/Default";

import illustration from "assets/img/auth/auth.png";
import {useAuth} from "hooks/useAuth";
import Input from "../../../components/form/Input";
import {login as callLogin} from "../../../api/auth-api";
import {useLocalStorage} from "../../../hooks/useLocalStorage";


const schema = z.object({
  email: z.string().email('A valid email address is required'),
  password: z.string().min(1, {message: 'Password required'}),
});

type FormValues = z.infer<typeof schema>

const defaultValues: FormValues = {
  email: '',
  password: '',
}

function SignIn() {
  const {
    control,
    register,
    handleSubmit,
    formState: {errors},
  } = useForm({
    defaultValues,
    mode: 'all',
    resolver: zodResolver(schema),
  });
  const navigate = useNavigate();

  const textColor = useColorModeValue("navy.700", "white");
  const buttonVariant = useColorModeValue( "photonDark", "photonLight");
  const textColorSecondary = "gray.400";
  const textColorBrand = useColorModeValue("brand.500", "white");

  const {login, user} = useAuth();

  const [show, setShow] = React.useState(false);
  const [error, setError] = React.useState(null)
  const [isSubmitting, setSubmitting] = React.useState(false);
  const [previousPath, setPreviousPath] = useLocalStorage('previousPath', null);

  const handleClick = () => setShow(!show);

  const onSubmit = async (data: FormValues) => {
    setError(null);
    setSubmitting(true)

    try {
      const loginResponse = await callLogin(data.email, data.password);
      const user = loginResponse.data;
      login(user);

      if (previousPath && !previousPath.includes('login')) {
        navigate(previousPath, {replace: true});
      } else {
        navigate("/intel/projects", {replace: true});
      }
      setPreviousPath(null);
    } catch (e: any) {
      console.error(e);
      const status = e?.response?.status;
      const message = e?.response?.data?.error_message;
      if (message) {
        setError(message);
      } else {
        if (status === 401) {
          setError('Invalid credentials. Please try again.');
        } else {
          setError('Server error, please try again later.');
        }
      }
    }

    setSubmitting(false);
  }

  React.useEffect(
    () => {
      if (user) {
        navigate('/intel/projects', { replace: true });
        return;
      }
    },
    [user]
  );

  return (
    <DefaultAuth
      illustrationBackground={illustration}
      image={illustration}
    >
      <Flex
        maxW={{base: "100%", md: "max-content"}}
        w='100%'
        mx={{base: "auto", lg: "0px"}}
        me='auto'
        h='100%'
        alignItems='start'
        justifyContent='center'
        mb={{base: "30px", md: "60px"}}
        px={{base: "25px", md: "0px"}}
        mt={{base: "40px", md: "14vh"}}
        flexDirection='column'
      >
        <Box me='auto'>
          <Heading color={textColor} fontSize='36px' mb='10px'>
            Sign In
          </Heading>
          <Text
            mb='36px'
            ms='4px'
            color={textColorSecondary}
            fontWeight='400'
            fontSize='md'>
            Enter your email and password to sign in!
          </Text>
        </Box>
        <Flex
          zIndex='2'
          direction='column'
          w={{base: "100%", md: "420px"}}
          maxW='100%'
          background='transparent'
          borderRadius='15px'
          mx={{base: "auto", lg: "unset"}}
          me='auto'
          mb={{base: "20px", md: "auto"}}
          as='form'
          onSubmit={handleSubmit(onSubmit)}
        >
          <Input
            error={errors.email}
            field="email"
            registerProps={register('email')}
            required={true}
            type='email'
            placeholder='mail@photon.rocks'
            inputStyles={{
              variant: 'auth',
              fontSize: 'sm',
              ms: {base: "0px", md: "0px"},
              mb: '24px',
              size: 'lg',
            }}
          >
            Email
          </Input>
          <Input
            error={errors.password}
            field="password"
            required={true}
            type={show ? "text" : "password"}
            registerProps={register('password')}
            inputStyles={{
              fontSize: 'sm',
              mb: '24px',
              size: 'lg',
              variant: 'auth',
            }}
            rightAddon={
              <InputRightElement display='flex' alignItems='center' mt='4px'>
                <Icon
                  color={textColorSecondary}
                  _hover={{cursor: "pointer"}}
                  as={show ? RiEyeCloseLine : MdOutlineRemoveRedEye}
                  onClick={handleClick}
                />
              </InputRightElement>
            }
          >
            Password
          </Input>

          <Flex justifyContent='right' mb='24px'>
            <NavLink to='/forgot-password'>
              <Text
                color={textColorBrand}
                fontSize='sm'
                w='124px'
                fontWeight='500'>
                Forgot password?
              </Text>
            </NavLink>
          </Flex>
          <Button
            type="submit"
            fontSize='sm'
            variant={buttonVariant}
            fontWeight='500'
            w='100%'
            h='50'
            mb='24px'
            isLoading={isSubmitting}
            loadingText="Please wait..."
          >
            Sign In
          </Button>
          {
            error
            && <Text color="red.500">{error}</Text>
          }
        </Flex>
      </Flex>
    </DefaultAuth>
  );
}

export default SignIn;
