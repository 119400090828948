import React from 'react';
import {createRoot} from 'react-dom/client';

import {RouterProvider} from 'react-router-dom';

import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import BugsnagPerformance from '@bugsnag/browser-performance';

import {ChakraProvider} from '@chakra-ui/react';

import packageInfo from '../package.json';
import config from './config';
import './assets/css/App.css';
import theme from './theme/theme';
import {router} from "./App";
import {AuthProvider} from "./hooks/useAuth";


Bugsnag.start({
  apiKey: '6c58281178e88aee61b2302caa1d8560',
  plugins: [new BugsnagPluginReact()],
  appVersion: packageInfo.version,
  releaseStage: config.environment,
  enabledReleaseStages: ['production', 'staging', 'testing'],
});
BugsnagPerformance.start({apiKey: '6c58281178e88aee61b2302caa1d8560'});

const ErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React);

const root = createRoot(document.getElementById('root'));
root.render(
  <ErrorBoundary>
    <ChakraProvider theme={theme}>
      <React.StrictMode>
        <AuthProvider>
          <RouterProvider router={router}/>
        </AuthProvider>
      </React.StrictMode>
    </ChakraProvider>
  </ErrorBoundary>
);
