/*eslint-disable*/
import { Flex, Link, List, Box,  useColorModeValue, Text } from '@chakra-ui/react';
import { FaFacebook, FaInstagram } from 'react-icons/fa';
import CopyButton from './components/copyButton';






export default function Footer() {
	const textColor = useColorModeValue('gray.400', 'white');
	return (
		<Flex
			direction='column'
			mb='10px'
			>
			<Text
				mr='20px'
				mb='10px'
				textAlign='center'
				color='white'
				>
				{' '}
				&copy; {new Date().getFullYear()}
				<Text  as='span' fontWeight='500' ms='4px'>
          Photon Technologies Ltd. 2018-{new Date().getFullYear()}. - All rights reserved
				</Text>
			</Text>
			<Flex justifyContent='space-around' m='0 10px'>
				<Box mt='5px'>
						<Link href='https://www.instagram.com/photon.rocks/' target= '_blank'>
      				<FaInstagram size={30} color='white' />
					  </Link>
				</Box>
				<Box mt='5px'>
						<Link href='https://www.facebook.com/thephoton.hu' target= '_blank'>
							<FaFacebook size={30} color='white' />
						</Link>
				</Box>
				<Box>
					<CopyButton email={"hello@thephoton.hu"} label="email" />
				</Box>
				<Box mr='10px'>
					<CopyButton email={"support@thephoton.hu"} label="support email" />
				</Box>
			</Flex>
		</Flex>
	);
}
